<template>
    <div class="view pa24">
        <div class="d-flex">
            <div>
                <el-input class="w200 mb10 mr10" placeholder="请输入关键字" v-model="searchData.name" />
                <el-button type="primary" @click="searchFun">搜索</el-button>
                <!-- <el-button type="primary">导出数据</el-button> -->
            </div>
            <div style="margin-left: auto">
                <el-button class="ma mr10" type="primary" @click="showAddModel">新增房屋</el-button>
                <!-- <el-button class="ma" type="primary" @click="showAddModel">导入房号</el-button> -->
            </div>
        </div>
        <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
            <template v-slot:table>
                <el-table-column type="index" align="center" label="序号" />
                <el-table-column prop="buildName" align="center" label="所属楼宇" show-overflow-tooltip />
                <el-table-column prop="houseName" align="center" label="房屋名称" show-overflow-tooltip />
                <el-table-column prop="houseTypeName" align="center" label="房屋类型" show-overflow-tooltip />
                <el-table-column prop="number" align="center" label="单元" show-overflow-tooltip />
                <el-table-column prop="layers" align="center" label="楼层" show-overflow-tooltip >
                    <template slot-scope="scope">
                        {{scope.row.layers}}层
                    </template>
                </el-table-column>
                <el-table-column prop="houseArea" align="center" label="房屋面积" show-overflow-tooltip >
                    <template slot-scope="scope">
                        {{scope.row.houseArea}}㎡
                    </template>
                </el-table-column>
                <el-table-column prop="carArea" align="center" label="车位面积" show-overflow-tooltip >
                    <template slot-scope="scope">
                        {{scope.row.carArea}}㎡
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="remark" align="center" label="描述" show-overflow-tooltip /> -->
                <el-table-column align="center" label="操作" width="110">
                    <template slot-scope="scope">
                        <el-button type="text" @click="showUpdateModel(scope.row)">编辑</el-button>
                        <el-button style="color:#F56C6C" type="text" @click="delHouse(scope.row)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </template>
        </commonTable>

        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="modelTitle" :visible.sync="showModel" :destroy-on-close="true"
            width="550px" center>
            <el-form :model="formData" :rules="rules" ref="formData" label-width="150px">
                <el-form-item label="所属楼宇" >
                    <el-input class="w220" disabled v-model="buildName" placeholder="请输入房屋名称"></el-input>
                </el-form-item>
                <el-form-item label="房屋名称" prop="houseName">
                    <el-input class="w220" v-model="formData.houseName" placeholder="请输入房屋名称"></el-input>
                </el-form-item>
                <el-form-item label="单元" prop="number">
                    <el-input class="w220 suffixInput" v-model="formData.number" type="number" placeholder="请输入单元">
                        <template slot="append">单元</template>
                    </el-input>
                    <el-alert v-if="unitNumber" style="line-height:15px;" type="warning">
                        当前楼宇最多只能填写单元{{ unitNumber > 1 ? 1 + "~" + unitNumber : unitNumber }}
                    </el-alert>

                </el-form-item>
                <el-form-item label="楼层" prop="layers">
                    <el-input class="w220 suffixInput" v-model="formData.layers" type="number" placeholder="请输入楼层">
                        <template slot="append">楼</template>
                    </el-input>
                    <el-alert v-if="layersNumber" style="line-height:15px;" type="warning">当前楼宇最高只能填写{{
                            layersNumber
                    }}层</el-alert>
                </el-form-item>
                <el-form-item label="房屋类型" prop="houseType">
                    <el-select class="w220" v-model="formData.houseType" placeholder="请选择房屋类型">
                        <el-option v-for="(name, id) of houseTypeData" :key="id" :value="Number(id)" :label="name">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="房屋面积" prop="houseArea">
                    <el-input class="w220 suffixInput" v-model="formData.houseArea" placeholder="请输入建筑面积" type="number">
                        <template slot="append">平方米</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="车位面积" prop="carArea">
                    <el-input class="w220 suffixInput" v-model="formData.carArea" placeholder="请输入车位面积" type="number">
                        <template slot="append">平方米</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="阁楼面积" prop="carArea">
                    <el-input class="w220 suffixInput" v-model="formData.loftArea" placeholder="请输入阁楼面积" type="number">
                        <template slot="append">平方米</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="储藏室面积" prop="storageArea">
                    <el-input class="w220 suffixInput" v-model="formData.storageArea" placeholder="请输入储藏室面积"
                        type="number"><template slot="append">平方米</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="是否可以作为会议室" prop="isSubscribe">
                    <el-radio-group v-model="formData.isSubscribe">
                        <el-radio :label="0">否</el-radio>
                        <el-radio :label="1">是</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="会议室注意事项" v-if="formData.isSubscribe===1" prop="levelTarget">
                    <el-input v-model="formData.remark" :rows="3" type="textarea" placeholder="请输入描述">
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showModel = false">取 消</el-button>
                <el-button type="primary" @click="addUpdate">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { getHouseManageList, setHouseManage,getBuildManage,delHouseManage } from "@/api/propertyServices";
export default {
    name: "houseTable",
    components: {
        commonTable,
    },
    data() {
        return {
            //列表配置
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            showModel: false,
            tableData: [],
            searchData: { name: "" },
            userInfo: this.$store.state.loginRoot.userInfo,
            //模态框配置
            modelTitle: "",
            formData: {
                houseName: "",//房屋名称
                layers: "",//楼层
                number: "",//单元
                carArea: "",//车位面积
                familyId: "",//住户id 企业数据
                familyType: "",//住户类型（0：企业，1：个人）
                houseArea: "",//房屋面积
                houseType: "",//房屋类型（0：住房，1：办公室，2：商铺，3：厂房）
                isSubscribe: 0,//是否可以作为会议室（0：否，1：是）
                loftArea: "",//	阁楼面积
                storageArea: "",//储藏室面积
                remark: `1、请提前5-10分钟到达场地
2、错过时间则无法进入会议室，请再次预约`,//描述
            },
            rules: {
                houseName: {
                    required: true,
                    trigger: "blur",
                    message: "请输入房屋名称",
                },
                number: {
                    required: true,
                    trigger: "blur",
                    message: "请输入单元",
                },
                layers: {
                    required: true,
                    trigger: "blur",
                    message: "请输入楼层",
                },
                houseArea: {
                    required: true,
                    trigger: "blur",
                    message: "请输入房屋面积",
                },
            },
            formType: "",
            updateId: 0,
            buildData: {},
            familyTypeData: {
                0: "企业", 1: "个人"
            },
            houseTypeData: {
                0: "住房", 1: "办公室", 2: "商铺", 3: "厂房"
            },
            isSubscribeData: {
                0: "否", 1: "是"
            },
            buildId:0,
            buildName:"",
            unitNumber: 0,//选中的楼宇有多少个单元
            layersNumber: 0,//选中的楼宇有多少 
        };
    },
    filters: {

    },
    async created() {
        this.buildId = this.$route.query.buildId;
        let checkedPark = sessionStorage.getItem('checkedPark');
        this.parkId = JSON.parse(checkedPark).parkId;
        await this.getBuildManage();
        this.getList();
    },
    methods: {
        /**@method 删除房屋 */
        delHouse(row) {
            this.$confirm('此操作将永久删除该房屋数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delHouseManage({ houseId: row.houseId }).then(res => {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.getList();
                }).catch(err => {
                    if (err.code === 201) {
                        this.$message({
                            type: 'error',
                            message: err.message
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        /**@method 获取楼宇详情 */
        async getBuildManage(){
            let res=await getBuildManage(this.buildId);
            try{
                this.buildName=res.data.buildName;
                this.unitNumber=res.data.buildNum;
                this.layersNumber=res.data.buildLayers;
            }catch(err){
                this.buildName="";
            }
        },
        /**@method 编辑/新增 */
        addUpdate() {
            //修改添加会员等级
            this.$refs.formData.validate(async (valid) => {
                if (valid) {
                    let params = {
                        ...this.formData,
                        buildId:this.buildId
                    };
                    if (params.number > this.unitNumber) {
                        this.$message({
                            message: "不能超过楼宇所定的单元范围",
                            type: "error"
                        })
                        return;
                    }
                    if (params.layers > this.layersNumber) {
                        this.$message({
                            message: "不能超过楼宇所定的楼层高度",
                            type: "error"
                        })
                        return;
                    }
                    if(params.isSubscribe!==1){
                        params.remark="";
                    }
                    let message = "新增成功"
                    if (this.formType === 'update') {
                        message = "编辑成功"
                        params.houseId = this.updateId;
                    }
                    let res = await setHouseManage(params);
                    if (res.code === 200) {
                        this.$message({
                            message: message,
                            type: "success"
                        })
                        this.getList();
                        this.showModel = false;
                    }
                }
            });
        },
        /**@method 显示新增模态框 */
        showAddModel() {
            this.showModel = true;
            this.modelTitle = "新增房屋";
            this.formType = "add";
            this.formData = {
                houseName: "",//房屋名称
                layers: "",//楼层
                number: "",//单元
                carArea: "",//车位面积
                familyId: "",//住户id 企业数据
                familyType: "",//住户类型（0：企业，1：个人）
                houseArea: "",//房屋面积
                houseType: "",//房屋类型（0：住房，1：办公室，2：商铺，3：厂房）
                isSubscribe: 0,//是否可以作为会议室（0：否，1：是）
                loftArea: "",//	阁楼面积
                storageArea: "",//储藏室面积
                remark: `1、请提前5-10分钟到达场地
2、错过时间则无法进入会议室，请再次预约`,//描述
            };
            this.updateId = 0;
        },
        /**@method 编辑
         * @param {Object} val - 当前点击行的值
         */
        showUpdateModel(row) {
            this.showModel = true;
            this.modelTitle = "编辑房屋";
            this.formType = "update";
            this.updateId = row.houseId;
            this.formData = {
                houseName: row.houseName,//房屋名称
                layers: row.layers,//楼层
                number: row.number,//单元
                carArea: row.carArea,//车位面积
                familyId: row.familyId,//住户id 企业数据
                familyType: row.familyType,//住户类型（0：企业，1：个人）
                houseArea: row.houseArea,//房屋面积
                houseType: row.houseType,//房屋类型（0：住房，1：办公室，2：商铺，3：厂房）
                isSubscribe: row.isSubscribe,//是否可以作为会议室（0：否，1：是）
                loftArea: row.loftArea,//	阁楼面积
                storageArea: row.storageArea,//储藏室面积
                remark: row.remark,//描述
            };
        },
        /**@method 搜索 */
        searchFun() { 
            this.getList();
        },
        /**@method 获取列表 */
        async getList() {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                buildId: this.buildId
            };
            try {
                this.loading = true;
                let res = await getHouseManageList(params);
                this.loading = false;

                const { data } = res;
                let tableData = [];
                for (let row of data.pageInfo.list) {
                    row.familyTypeName = this.familyTypeData[row.familyType];
                    row.houseTypeName = this.houseTypeData[row.houseType];
                    row.isSubscribeName = this.isSubscribeData[row.isSubscribe];
                    tableData.push(row);
                }
                this.tableData = tableData;
                this.total = data.pageInfo.total;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>